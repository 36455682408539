<template>
  <div>
    <div class="topnav">
      <a v-for="m in menu" :key="m.id" :class="m.class" :href="m.url">{{ m.name }}</a>
    </div>
    <router-view />
  </div>
</template>


<script>

export default {
  name: "app",
  components: {
  },
  data() {
    return {
      show: true,
      menu: []
    };
  },
  methods: {
  },
  mounted() {
    console.log(this.$route.name);
    const d = new Date();
var month = d.getMonth()+1
var year = d.getFullYear()
    this.menu.push({
      id:1,
      url:'/moon/'+month+'/'+year+'/'+1,
      name:'The Moon',
      path:'moon'
    },
    {
      id:2,
      url:'/sun/'+month+'/'+year+'/'+2,
      name:'The Sun',
      path:'sun'
    },
    {
      id:3,
      url:'/Setting',
      name:'Setting',
      path:'Setting'
    })

    if (this.$route.name == null) {
      this.$router.push('/moon/'+month+'/'+year+'/'+1)
    }
    for (let m = 0; m < this.menu.length; m++) {
          this.menu[m].class = ''
          if (this.menu[m].path == this.$route.name) {
            this.menu[m].class = 'active'
          }
        }
  },
};
</script>

<style>
/* Add a black background color to the top navigation */
.topnav {
  background-color: #9867C5;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #ffff;
  color: black;
}
</style>