<template>
  <div class="mt-5 mb-5">
    <div class="container">
      <div style="text-align:right">
        <a @click="getid(0)">
                <button
                  type="button"
                  class="btn btn-success btn-md"
                  data-bs-toggle="modal"
                  data-bs-target="#AddUser"
                >
                  <i class="fa fa-plus"></i></button
              ></a></div>
      <table class="table table-bordered mt-3">
        <thead>
          <tr class="table-active">
            <th scope="col">No</th>
            <th scope="col">Name</th>
            <th scope="col">Color</th>
            <th scope="col">Icon</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody is="draggable" :list="list" tag="tbody" @change="onUnpublishedChange">
          <tr style="cursor: move" v-for="(l, i) in list" :key="i">
            <td style="width:20%">
              {{ i + 1 }}
            </td>
            <td style="width:20%">
              {{ l.name }}
            </td>
            <td style="width:20%"><div
                    class="square"
                    :style="'background-color:' + l.class"
                  ></div>
            </td>
            <td style="width:20%">
              <img :src="l.image" style="width:90px"/>
            </td>
            <td style="width:10%">
              <a @click="getid(l.id)">
                <button
                  type="button"
                  class="btn btn-warning btn-md"
                  data-bs-toggle="modal"
                  data-bs-target="#AddUser"
                >
                  <i class="fa fa-edit"></i></button
              >&nbsp;&nbsp; <button
                  type="button"
                  class="btn btn-danger btn-md"
                  data-bs-toggle="modal"
                  data-bs-target="#DeleteData"
                >
                  <i class="fa fa-remove"></i></button
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>    
      <!-- <div class="row">
        <div class="col-12" style="text-align: center">
          <button
            class="btn btn btn-success btn-sm"
            style="color: white; text-align: center"
            @click="saveorderlist()"
          >
            Save No
          </button>
        </div>
      </div> -->
    <!-- Modal -->
    <div
      class="modal fade"
      id="AddUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          </div>
          <div class="modal-body">
            <form>
                <div class="form-group">
                  <label for="username">Name</label>
                  <input
                    v-model="user.name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
              </div>
                <div class="form-group">
                  <label for="username">Color</label>
                  <input type="color" class="form-control form-control-color" id="exampleColorInput" :value="user.class"
          @change="selectcolor($event)" title="Choose your color" />
                </div>
                <div class="form-group">
                  <label for="username">Icon</label><br/>
                  <img class="mt-3" v-if="user.image" :src="user.image" width="30%">
          <input id="file" class="form-control mt-3" type="file" accept="image/*" @change="onFileChange"  />
              </div>
          </form>
          </div>
          <div class="modal-footer mt-3">
            <button
              type="button"
              class="btn btn-success btn-md"
              @click="save()"
            >
              Save
            </button>
            <button
            id="closedmenu"
              type="button"
              class="btn btn btn-secondary btn-md"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="DeleteData"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Want to delete?</h5>
          </div>
          <div class="modal-footer mt-3">
            <button
              type="button"
              class="btn btn-danger btn-md"
              @click="deletedata()"
            >
              Delete
            </button>
            <button
            id="closeddelete"
              type="button"
              class="btn btn btn-secondary btn-md"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/UserService";
import draggable from "vuedraggable";
import MoodService from "../services/MoodService";
import LinkService from "../services/LinkService";
import axios from "axios";

export default {
  name: "Nav",
  components: {
    draggable,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
      roles: [],
      image:'',
    };
  },
  mounted() {
    this.getMenus();
  },
  methods: {
    onUnpublishedChange(){
      for (let l = 0; l < this.list.length; l++) {
        console.log(l+1);
        var list = {
          no: l + 1,
        };
        MoodService.updateno(this.list[l].id, list).then(() => {
          if (l + 1 == this.list.length) {
            this.getMenus()
          }
        });
      }
    },
    deletedata(){
MoodService.deletemood(this.user_id).then(()=>{
  document.getElementById("closeddelete").click();
  this.getMenus()
})
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFileImage(files[0]);
      // this.createBase64Image(selectedImage);
    },
    onUploadFileImage(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkService.geturl()
      // console.log(this.data.type);
      const regex = /([\u0E00-\u0E7F]+)/gmu;
const str = selectedFile.name;
let m;
// console.log(selectedFile.type);
let checkthai = ''
let name = selectedFile.name
let typefile = (selectedFile.type).split('/')
while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
        regex.lastIndex++;
    }
    
    // The result can be accessed through the `m`-variable.
    m.forEach(() => {
        // console.log(`Found match, group ${groupIndex}: ${match}`);
checkthai = 1
    });
}
if (checkthai == 1) {
  name = this.makeid(5)+'.'+typefile[1]
}
// console.log(name);
      var http = link + '/uploadimg?name=' + name;
      var path = link + '/uploads/';
      axios
        .post(http, formData)
        .then(() => {
          // console.log(path + res.data.path);
          this.user.image = path + name
          this.image = path + name
          console.log(this.user);
          // console.log(res.data);
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveorderlist() {
      
    },
    getRoles() {
      UserService.getRolesAll().then((res) => {
        // console.log(res.data);
        this.roles = res.data;
      });
    },
    selectcolor(event) {
      this.user.class = event.target.value;
    },
    makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
},
    getid(id) {
      document.getElementById("file").value = "";
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "Edit";
        MoodService.getmood(this.user_id).then((res) => {
          this.user = res.data;
          console.log(res.data);
        });
      }else{
        this.user = []
        this.title = "Add";
      }
    },
    save() {
      if (this.user.name == null || this.user.name == '') {
        alert("Please enter name");
      } else if (this.user.class == null || this.user.class == '') {
        alert("Please enter color");
      } else {
        var userdata = {
          name: this.user.name,
          class: this.user.class,
          image: this.user.image,
          status: 1,
          no:this.list.length+1
        };
        console.log(userdata);
        if (this.user_id == 0) {
          MoodService.createmood(userdata).then(() => {
                document.getElementById("closedmenu").click();
                this.getMenus();
                document.getElementById("file").value = "";
              });
        } else {
          MoodService.updatemood(this.user_id, userdata).then((res) => {
            console.log(res.data);
            if (res.data) {
              document.getElementById("closedmenu").click();
              this.getMenus();
              document.getElementById("file").value = "";
            }
          });
        }
      }
    },
    getMenus() {
      MoodService.getmoods().then((res) => {
        this.list = res.data;
        console.log(res.data);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
.square {
  height: 50px;
  width: 50px;
  background-color: #555;
}
</style>
