import http from "../http-common";

class CountService {
  createcount(data) {
    return http.post('/counts', data);
  }
  getcounts(mood,month,year,userId) {
    return http.get(`/counts?moodId=`+mood+'&&month='+month+"&&year="+year+"&&userId="+userId);
  }
  getcount(id) {
    return http.get(`/counts/` + id);
  }
  updatecount(id, data) {
    return http.put(`/counts/${id}`, data);
  }
}

export default new CountService();