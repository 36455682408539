import http from "../http-common";

class MoodTrackingService {
  createmood_tracking(data) {
    return http.post('/mood_tracking', data);
  }
  getmood_trackings(month,year,userId) {
    return http.get(`/mood_tracking/?month=`+month+'&&year='+year+'&&userId='+userId);
  }
  getmood_tracking(id) {
    return http.get(`/mood_tracking/` + id);
  }
  updatemood_tracking(id, data) {
    return http.put(`/mood_tracking/${id}`, data);
  }
  deletemood_tracking(id) {
    return http.delete(`/mood_tracking/` + id);
  }
}

export default new MoodTrackingService();