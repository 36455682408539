<template>
  <div class="container" v-if="show">
      <apexchart type="pie" :options="options" :series="series"></apexchart>     
      <div v-if="this.$route.params.month == 12">
        <h5 class="mt-5">Annual Overview</h5>
        <apexchart type="donut" :options="optionsdonut" :series="seriesdonut"></apexchart>
</div>
    </div>
</template>

<script>
import CountService from "../services/CountService";
import MoodService from "../services/MoodService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components:{
  },
  data() {
    return {
      show:false,
      list:[],
      listdonut:[],
      options: {
        chart: {
          width: 100,
          type: 'pie',
          id: 'vuechart-example',
          toolbar: {
      show: false
    },
    
        },
        responsive: [{
          breakpoint: 50,
          options: {
            chart: {
              width: 350
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      series: [],
      seriesdonut:[],
      optionsdonut: {
        chart: {
          width: 100,
          type: 'donut',
          id: 'vuechart-example',
          toolbar: {
      show: false
    },
    
        },
        responsive: [{
          breakpoint: 50,
          options: {
            chart: {
              width: 350
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    };
  },
  mounted() {
this.getUrl()
this.getUrldonut()

  },
  methods: {
    async getCount(){
// console.log(full);
// console.log(this.list);
var no = []
for (let c = 0; c < this.list.length; c++) {
  // console.log(this.list[c].id);
  await CountService.getcounts(this.list[c].id,this.$route.params.month,this.$route.params.year,this.$route.params.id).then((res)=>{
    no.push(res.data[0].count)
    // console.log(res.data[0].count);
    if (c+1 == this.list.length) {
      // console.log(no);
      this.series = no
      this.show = true
      // console.log(this.options);
    }
});
  
}
    },
    getUrl(){
      MoodService.getmoods().then((res) => {
this.list = res.data
        // console.log(res.data);
        var dt = []
        var color =[]
        var img =[]
        for (let r = 0; r < res.data.length; r++) {
          dt.push(res.data[r].name)
          color.push(res.data[r].class)
          img.push(res.data[r].image)
          if (r+1 == res.data.length) {
            // console.log(dt);
        this.options.labels = dt;
        this.options.colors = color;
        // this.options.fill.image.src = img
        // console.log(img);
            this.getCount()
          }
        }
      });
    },
    async getCountdonut(){
// console.log(full);
// console.log(this.list);
var no = []
for (let c = 0; c < this.listdonut.length; c++) {
  // console.log(this.list[c].id);
  await CountService.getcounts(this.list[c].id,'',this.$route.params.year,this.$route.params.id).then((res)=>{
    no.push(res.data[0].count)
    // console.log(res.data[0].count);
    if (c+1 == this.listdonut.length) {
      // console.log(no);
      this.seriesdonut = no
      this.show = true
      // console.log(this.options);
    }
});
  
}
    },
    getUrldonut(){
      MoodService.getmoods().then((res) => {
this.listdonut = res.data
        // console.log(res.data);
        var dt = []
        var color =[]
        var img =[]
        for (let r = 0; r < res.data.length; r++) {
          dt.push(res.data[r].name)
          color.push(res.data[r].class)
          img.push(res.data[r].image)
          if (r+1 == res.data.length) {
            // console.log(dt);
        this.optionsdonut.labels = dt;
        this.optionsdonut.colors = color;
        // this.options.fill.image.src = img
        // console.log(img);
            this.getCountdonut()
          }
        }
      });
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
