import Vue from "vue";
import Router from "vue-router";
import Dashboard from './components/Dashboard.vue';
import Login from './components/Login.vue';
import Users from './components/Users.vue';
import Menu from './components/Menu.vue';
import Menumanage from './components/Menumanage.vue';
import Url from './components/Url.vue';
import QrcodeWeb from './components/QrcodeWeb.vue';
import Moon from './components/Moon.vue';
import Sun from './components/Sun.vue';
import Mood from './components/Mood.vue';

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/Dashboard",
      alias: "/Dashboard",
      name: "Dashboard",
      component: Dashboard
    },
    {
      path: "/Setting",
      alias: "/Setting",
      name: "Setting",
      component: Mood
    },
    {
      path: "/moon/:month/:year/:id",
      alias: "/moon",
      name: "moon",
      component: Moon
    },
    {
      path: "/sun/:month/:year/:id",
      alias: "/sun",
      name: "sun",
      component: Sun
    },
    {
      path: "/login",
      alias: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/Users",
      alias: "/Users",
      name: "Users",
      component: Users
    },
    {
      path: "/menu",
      alias: "/menu",
      name: "menu",
      component: Menu
    },
    {
      path: "/menumanage",
      alias: "/menumanage",
      name: "menumanage",
      component: Menumanage
    },
    {
      path: "/url",
      alias: "/url",
      name: "url",
      component: Url
    },
    {
      path: "/qrcode",
      alias: "/qrcode",
      name: "qrcode",
      component: QrcodeWeb
    },
  ]
});


// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login','/home','/qrcode','/main'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('tmd');
//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   // console.log(loggedIn);
//   if (authRequired && !loggedIn) {
//     next('/home');
//     // next();
//   } else {
//     next();
//   }

// });

export default router;
